import { Button } from "@components/ui/button"
import { Text } from "@components/ui/typograhpy/Text"
import { motion } from "framer-motion"
import Link from "next/link"
import { FC } from "react"
import * as styles from "./Notice.styles"

type CookieNoticeProps = {
  onClickAgree: () => void
  onClickMoreOptions: () => void
}

export const CookieNotice: FC<CookieNoticeProps> = ({
  onClickAgree,
  onClickMoreOptions,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ ease: "easeInOut", duration: 0.3, delay: 1 }}
      className={styles.parent}
    >
      <div className={styles.container}>
        <Text variant="small">
          We use cookies to analyze site performance and deliver personalized
          content. By clicking “Agree”, you consent to our{" "}
          <Link href="/cookie-policy">
            <a className={styles.link}>Cookie Policy</a>
          </Link>
          . You may change your settings at any time.
        </Text>
        <div className={styles.buttons}>
          <Button variant="primary" onClick={onClickAgree}>
            Agree
          </Button>
          <Button variant="secondary" onClick={onClickMoreOptions}>
            More options
          </Button>
        </div>
      </div>
    </motion.div>
  )
}
