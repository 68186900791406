import { useRouter } from "next/router";
import React, { FC } from "react";
import * as Portal from "@radix-ui/react-portal";
import { Text } from "@components/ui/typograhpy/Text";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import Link from "next/link";
export const ExitPreview: FC = () => {
  const {
    isPreview
  } = useRouter();
  return <>
      {isPreview && <Portal.Root>
          <Link href="/api/exit-preview" className={buttonContainer}>
            <Text variant="small" color="shade800">
              Exit preview
            </Text>
          </Link>
        </Portal.Root>}
    </>;
};
const buttonContainer = parse({
  position: "fixed",
  bg: "shade300"
}, "blwfnx6");

require("./ExitPreview.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ExitPreview.tsx");