import { Cookie } from "@components/cookie";
import { Debug } from "@components/Debug";
import { ExitPreview } from "@components/ExitPreview";
import { Head } from "@components/head";
import { Tracking } from "@components/Tracking";
import "@config/theme/utility-styles";
import { queryClient } from "@lib/query-client";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { domMax, LazyMotion } from "framer-motion";
import { SessionProvider } from "next-auth/react";
import PlausibleProvider from "next-plausible";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "../styles/font-face.css";
import "../styles/global-styles.css";
import "../styles/reset.css";
const PLAUSIBLE_DOMAIN = process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "viesus.cloud" : "dev.viesus.cloud";
function MyApp({
  Component,
  pageProps
}) {
  console.info(`[plausible-domain] ${PLAUSIBLE_DOMAIN}`);
  return <>
      <Head />
      <Debug />
      <Tracking />
      <ExitPreview />
      <PlausibleProvider domain={PLAUSIBLE_DOMAIN} enabled trackLocalhost trackOutboundLinks>
        <TooltipProvider>
          <SessionProvider session={pageProps.session}>
            <QueryClientProvider client={queryClient}>
              <LazyMotion features={domMax}>
                <Component {...pageProps} />
              </LazyMotion>
              {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
            </QueryClientProvider>
          </SessionProvider>
        </TooltipProvider>
      </PlausibleProvider>
      <Cookie />
    </>;
}
export default MyApp;