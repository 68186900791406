import { parseAll, Theme } from "@config/theme"
import { cx } from "@linaria/core"
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from "react"
import { ThemeSystemProps } from "theme-system"
import { getIcon } from "./helpers/get-icon"
import { ButtonLoadingSvg } from "./Loading"
import * as styles from "./styles"

export type ButtonUiProps = {
  variant?: "primary" | "secondary" | "danger" | "white"
  status?: "disabled" | "idle" | "loading"
  as?: "button" | "link" | "span"
}

type LinkVariant = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
  as?: "link"
  ref?: any
  href?: string
}
type ButtonVariant = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
  as?: "button"
}
type SpanVariant = Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
  as?: "span"
}

export type ButtonIcon =
  | "folder"
  | "download"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "edit"
  | "fullscreen"
  | "apple"
  | "google"
  | "icon-unread"
  | "check"
  | "contact"
  | "select"
  | "upload"
  | "external"
  | "plus"
  | "delete"
  | "refresh"
  | "copy"
  | "enhance"
  | "arrow-left"
  | "dots"
  | "settings"
  | "deselect"
  | "workflows"
  | "code"
  | "rocket"
  | "duplicate"

export type LinkType = ButtonVariant | LinkVariant | SpanVariant

type ButtonSize = "small" | "regular"

type ButtonProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt" | "color"> &
  ButtonUiProps &
  LinkType & {
    size?: ButtonSize
    leftIcon?: ButtonIcon
    rightIcon?: ButtonIcon
    width?: "auto" | "fill"
  }

export const Button = forwardRef<any, ButtonProps>((props, ref) => {
  const {
    variant = "primary",
    width = "auto",
    status = "idle",
    size = "regular",
    as = "button",
    leftIcon,
    rightIcon,
    children,
    className,
    mb,
    mt,
    color = "inherit",
    ...rest
  } = props
  const Component = as === "link" ? "a" : as === "button" ? "button" : "span"
  return (
    // @ts-ignore
    <Component
      ref={ref}
      className={cx(
        styles.basics,
        styles.button,
        styles.variant,
        className,
        parseAll({ mb, mt, color })
      )}
      data-variant={variant}
      data-status={status}
      data-fill-width={width === "fill" ? "" : undefined}
      data-size={size}
      disabled={status === "disabled" || status === "loading"}
      {...rest}
    >
      <span className={styles.content}>
        {leftIcon && (
          <span className={styles.leftIcon}>
            {getIcon(leftIcon, size === "regular" ? 24 : 20)}
          </span>
        )}
        <span data-button-text>{children}</span>
        {rightIcon && (
          <span className={styles.rightIcon}>
            {getIcon(rightIcon, size === "regular" ? 24 : 20)}
          </span>
        )}
      </span>
      {status === "loading" && <ButtonLoadingSvg />}
    </Component>
  )
})
Button.displayName = "Button"
