import { useEffect } from "react"

export const Debug = () => {
  useEffect(() => {
    window["__DEBUG__"] = {
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      api: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
      pusherKey: process.env.NEXT_PUBLIC_PUSHER_KEY,
    }
  }, [])
  return null
}
