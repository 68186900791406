import { useRouter } from "next/router"
import { FC, useEffect } from "react"
import Script from "next/script"
import { useCookie } from "@lib/hooks/use-cookie"

type TrackingProps = {}

export const Tracking: FC<TrackingProps> = () => {
  const router = useRouter()
  const { preferences } = useCookie()
  useEffect(() => {
    pageview(router.asPath)
  }, []) // eslint-disable-line

  useEffect(() => {
    router.events.on("routeChangeComplete", pageview)
    return () => {
      router.events.off("routeChangeComplete", pageview)
    }
  }, [router])

  return (
    <>
      {preferences.includes("analytical") && (
        <Script
          id="hotjar"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2798762,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `,
          }}
        />
      )}
      {preferences.includes("personal") && (
        <>
          <Script
            id="tag-manager"
            strategy="afterInteractive"
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-240015106-1"
          />
          <Script
            id="gtag"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-240015106-1');`,
            }}
          />
        </>
      )}
    </>
  )
}

function pageview(url: string) {
  if (window["gtag"]) {
    window["gtag"]("config", process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
      page_path: `/${url}`,
    })
  }
}
