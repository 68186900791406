import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { darken, rem, rgba } from "polished";
export const basics = parse({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}, "bn3kytl");
export const button = parse({
  fontWeight: "semiBold",
  px: {
    _: "24"
  }
}, "b1rifbkc");
export const emojiButton = "e1ycbwiw";
export const iconButton = "i1phakaf";
export const variant = "v1leq33y";
export const loadingSvg = parse({
  position: "absolute"
}, "l1gbiu1i");
export const rightIcon = parse({
  display: "flex",
  ml: "8"
});
export const leftIcon = parse({
  display: "flex",
  mr: "8"
});
export const content = parse({
  display: "flex",
  alignItems: "center"
});

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");