import create from "zustand"
import Cookie from "js-cookie"
import { useCallback, useEffect } from "react"
import { useRouter } from "next/router"

export type CookieOption = "personal" | "analytical" | "functional"

export const COOKIE_KEY = "vc-cookie-settings"

type CookieState = {
  preferences: CookieOption[]
  setPreferences: (settings: CookieOption[]) => void
}

const useStore = create<CookieState>((set) => ({
  preferences: ["functional"],
  setPreferences: (preferences) => {
    const settings = {
      preferences,
      acceptedAt: new Date(),
    }
    Cookie.set(COOKIE_KEY, JSON.stringify(settings), {
      expires: 365,
    })
    return set({ preferences: preferences })
  },
}))

export function useCookie() {
  const { preferences, setPreferences } = useStore((state) => state)
  const router = useRouter()

  useEffect(() => {
    const cookie = Cookie.get(COOKIE_KEY)
    // Set cookie preferences if not set
    if (cookie) {
      const { preferences } = JSON.parse(cookie)
      setPreferences(preferences)
    }
  }, [setPreferences])

  const acceptAll = useCallback(() => {
    setPreferences(["functional", "analytical", "personal"])
  }, [setPreferences])

  const acceptFuntional = useCallback(() => {
    setPreferences(["functional"])
  }, [setPreferences])

  let shouldDisplay = false
  const cookie = Cookie.get(COOKIE_KEY)
  if (!cookie && !router.pathname.includes("/app")) {
    shouldDisplay = true
  }

  return { preferences, acceptAll, shouldDisplay, acceptFuntional }
}
