import * as Dialog from "@radix-ui/react-dialog"
import React, { FC } from "react"
import { IconButton } from "../button/IconButton"
import { Heading } from "../typograhpy/Heading"
import * as styles from "./styles"

export type ModalOrSidebarProps = {
  title: string
  trigger?: React.ReactElement
  onOpenChange?: (open: boolean) => void
  open?: boolean
  disableClose?: boolean
  footer?: React.ReactElement
}

export const ModalOrSidebar: FC<ModalOrSidebarProps> = ({
  trigger,
  title,
  children,
  open,
  onOpenChange,
  disableClose = false,
  footer,
}) => {
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.grid}>
              <div className={styles.container}>
                <div className={styles.titleSection}>
                  <Heading variant="h5" as="h5" mb="0">
                    {title}
                  </Heading>
                  {!disableClose && (
                    <Dialog.Close asChild>
                      <IconButton
                        as="button"
                        size="small"
                        variant="secondary"
                        icon="close"
                        label={`Close ${title}`}
                      />
                    </Dialog.Close>
                  )}
                </div>
              </div>
              <div className={styles.childContainer}>{children}</div>
              {footer && <div className={styles.footer}>{footer}</div>}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
