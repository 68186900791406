import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const baseText = "b10rog9o";
export const special = parse({
  fontWeight: "semiBold"
}, "sovm70i");
export const large = "l16lh3za";
export const regular = "rb12orq";
export const small = "s14mi9t3";
export const micro = "m17yijpf";

require("./Text.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Text.styles.ts");