import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const overlay = parse({
  position: "fixed",
  height: "100%",
  width: "100%"
}, "o1j64o1m");
export const childContainer = parse({
  px: {
    _: "20",
    large: "32"
  },
  pb: {
    _: "20",
    large: "32"
  }
}, "c4gxxb8");
export const grid = parse({
  display: "grid",
  position: "relative",
  height: "100%",
  width: "100%"
}, "g23suq0");
export const contentContainer = parse({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: {
    _: "flex-end",
    large: "center"
  },
  height: "100%",
  width: "100%"
}, "c1k563mm");
export const content = parse({
  width: "100%",
  bg: "sidebar"
}, "c124zzkv");
export const container = parse({
  textAlign: "center"
}, "cc58smp");
export const titleSection = parse({
  pt: {
    _: "16",
    large: "24"
  },
  pb: {
    _: "16",
    large: "0"
  },
  mb: {
    _: "24",
    large: "32"
  },
  px: {
    _: "20",
    large: "32"
  },
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  justifyContent: "space-between"
}, "t1yzfqek");
export const footer = parse({
  p: {
    _: "20"
  }
}, "fwwbjn1");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");