import { DefaultSeo } from "next-seo"
import React, { FC } from "react"
import { Favicon } from "./Favicon"

export const Head: FC<{ children?: never }> = () => {
  return (
    <>
      <DefaultSeo
        defaultTitle="Viesus Cloud"
        description="Viesus™ is the leading fully automatic image enhancement software, used on tens of millions of images each day."
      />
      <Favicon />
    </>
  )
}
