import React, { FC } from "react"
import { loadingSvg } from "./styles"

type ButtonLoadingSvgProps = {
  size?: number
}

export const ButtonLoadingSvg: FC<ButtonLoadingSvgProps> = ({ size = 8 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 8 8"
      className={loadingSvg}
    >
      <circle cx="4" cy="4" r="4" fillOpacity="0.3" fill="#fff">
        <animate
          attributeName="fill-opacity"
          begin="0s"
          calcMode="linear"
          dur=".8s"
          from="0.1"
          repeatCount="indefinite"
          to="0.1"
          values=".1;1;.1"
        ></animate>
      </circle>
    </svg>
  )
}
