import { parse, Theme } from "@config/theme"
import { cx } from "@linaria/core"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import React from "react"
import { ThemeSystemProps } from "theme-system"
import { ButtonIcon, getIcon } from "./helpers/get-icon"
import { ButtonUiProps, LinkType } from "./index"
import { ButtonLoadingSvg } from "./Loading"
import * as styles from "./styles"

type IconButtonProps = ButtonUiProps &
  Pick<ThemeSystemProps<Theme>, "color"> &
  LinkType & {
    icon: ButtonIcon
    label: string
    size?: "extra-small" | "small" | "regular"
  }

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = "primary",
      status = "idle",
      icon,
      className,
      color = "white",
      as = "button",
      size = "regular",
      label,
      ...rest
    },
    ref
  ) => {
    const Component = as === "link" ? "a" : as === "button" ? "button" : "span"
    return (
      // @ts-ignore
      <Component
        ref={ref}
        className={cx(
          styles.basics,
          styles.iconButton,
          styles.variant,
          className,
          parse({ color })
        )}
        data-variant={variant}
        data-size={size}
        data-status={status}
        disabled={status === "disabled" || status === "loading"}
        {...rest}
      >
        <span className={styles.content}>
          {getIcon(icon, variant === "secondary" ? 16 : 24)}
        </span>
        <VisuallyHidden.Root>{label}</VisuallyHidden.Root>
        {status === "loading" && <ButtonLoadingSvg />}
      </Component>
    )
  }
)

IconButton.displayName = "IconButton"
