import React, { FC } from "react";
import { ModalOrSidebar } from "@components/ui/modal-sidebar";
import { Text } from "@components/ui/typograhpy/Text";
import { Button } from "@components/ui/button";
import { theme } from "@config/theme";
import { css } from "@linaria/core";
import Link from "next/link";
type CookieModalProps = {
  onClickAcceptAll: () => void;
  onClickAcceptFuntional: () => void;
};
export const CookieModal: FC<CookieModalProps> = ({
  onClickAcceptAll,
  onClickAcceptFuntional
}) => {
  return <ModalOrSidebar open title={"Cookies"} disableClose>
      <div>
        <Text variant="regular" mb="16">
          We use cookies to remember your login details, provide a secure log-in
          experience, collect statistics to optimize site functionality, and
          deliver content and advertising tailored to your interests. You can
          view detailed descriptions of the types of cookies we use in our{" "}
          <Link href="/cookie-policy">
            <a className={link}>Cookie Policy</a>
          </Link>
          . <br />
          <br />
          Click Agree to accept all cookies or Decline to allow only necessary
          cookies. You may change your preferences at any time by clicking the
          ”Cookies“ link in the footer of the page.
        </Text>
        <div className={buttons}>
          <Button variant="primary" onClick={onClickAcceptAll}>
            Agree
          </Button>
          <Button variant="secondary" onClick={onClickAcceptFuntional}>
            Decline
          </Button>
        </div>
      </div>
    </ModalOrSidebar>;
};
const buttons = "bi28toc";
const link = "l1roys9w";

require("./Modal.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Modal.tsx");