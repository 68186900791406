import dynamic from "next/dynamic"

export type ButtonIcon =
  | "folder"
  | "download"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "edit"
  | "fullscreen"
  | "apple"
  | "google"
  | "icon-unread"
  | "check"
  | "contact"
  | "select"
  | "upload"
  | "external"
  | "plus"
  | "delete"
  | "refresh"
  | "copy"
  | "enhance"
  | "arrow-left"
  | "dots"
  | "settings"
  | "deselect"
  | "rocket"
  | "workflows"
  | "code"
  | "duplicate"

export function getIcon(icon: ButtonIcon, size: number) {
  if (icon === "folder") {
    const Icon = dynamic(() => import("../../icons/IconFolder"))
    return <Icon size={size} />
  }
  if (icon === "download") {
    const Icon = dynamic(() => import("../../icons/IconDownload"))
    return <Icon size={size} />
  }
  if (icon === "chevron-left") {
    const Icon = dynamic(() => import("../../icons/IconChevronLeft"))
    return <Icon size={size} />
  }
  if (icon === "chevron-right") {
    const Icon = dynamic(() => import("../../icons/IconChevronRight"))
    return <Icon size={size} />
  }
  if (icon === "close") {
    const Icon = dynamic(() => import("../../icons/IconClose"))
    return <Icon size={size} />
  }
  if (icon === "edit") {
    const Icon = dynamic(() => import("../../icons/IconEdit"))
    return <Icon size={size} />
  }
  if (icon === "fullscreen") {
    const Icon = dynamic(() => import("../../icons/IconFullscreen"))
    return <Icon size={size} />
  }
  if (icon === "google") {
    const Icon = dynamic(() => import("../../icons/IconGoogle"))
    return <Icon size={size} />
  }
  if (icon === "apple") {
    const Icon = dynamic(() => import("../../icons/IconApple"))
    return <Icon size={size} />
  }
  if (icon === "icon-unread") {
    const Icon = dynamic(() => import("../../icons/IconUnread"))
    return <Icon size={size} />
  }
  if (icon === "check") {
    const Icon = dynamic(() => import("../../icons/IconCheck"))
    return <Icon size={size} />
  }
  if (icon === "contact") {
    const Icon = dynamic(() => import("../../icons/IconContact"))
    return <Icon size={size} />
  }
  if (icon === "select") {
    const Icon = dynamic(() => import("../../icons/IconSelect"))
    return <Icon size={size} />
  }
  if (icon === "upload") {
    const Icon = dynamic(() => import("../../icons/IconUpload"))
    return <Icon size={size} />
  }
  if (icon === "external") {
    const Icon = dynamic(() => import("../../icons/IconExternal"))
    return <Icon size={size} />
  }
  if (icon === "plus") {
    const Icon = dynamic(() => import("../../icons/IconPlus"))
    return <Icon size={size} />
  }
  if (icon === "delete") {
    const Icon = dynamic(() => import("../../icons/IconDelete"))
    return <Icon size={size} />
  }
  if (icon === "refresh") {
    const Icon = dynamic(() => import("../../icons/IconRetry"))
    return <Icon size={size} />
  }
  if (icon === "copy") {
    const Icon = dynamic(() => import("../../icons/IconCopy"))
    return <Icon size={size} />
  }
  if (icon === "enhance") {
    const Icon = dynamic(() => import("../../icons/IconEnhanced"))
    return <Icon size={size} />
  }
  if (icon === "arrow-left") {
    const Icon = dynamic(() => import("../../icons/IconArrow"))
    return <Icon size={size} />
  }
  if (icon === "dots") {
    const Icon = dynamic(() => import("../../icons/IconDots"))
    return <Icon size={size} />
  }
  if (icon === "settings") {
    const Icon = dynamic(() => import("../../icons/IconSettings"))
    return <Icon size={size} />
  }
  if (icon === "deselect") {
    const Icon = dynamic(() => import("../../icons/IconDeselect"))
    return <Icon size={size} />
  }
  if (icon === "rocket") {
    const Icon = dynamic(() => import("../../icons/IconRocket"))
    return <Icon size={size} />
  }
  if (icon === "workflows") {
    const Icon = dynamic(() => import("../../icons/IconWorkflows"))
    return <Icon size={size} />
  }
  if (icon === "code") {
    const Icon = dynamic(() => import("../../icons/IconCode"))
    return <Icon size={size} />
  }
  if (icon === "duplicate") {
    const Icon = dynamic(() => import("../../icons/IconDuplicate"))
    return <Icon size={size} />
  }
}
