import { useCookie } from "@lib/hooks/use-cookie"
import { FC, useState } from "react"
import { CookieModal } from "./Modal"
import { CookieNotice } from "./Notice"

export const Cookie: FC = () => {
  const cookie = useCookie()
  const [showSettings, setShowSettings] = useState(false)

  if (!cookie.shouldDisplay) {
    return null
  }

  return showSettings ? (
    <CookieModal
      onClickAcceptAll={cookie.acceptAll}
      onClickAcceptFuntional={cookie.acceptFuntional}
    />
  ) : (
    <CookieNotice
      onClickAgree={cookie.acceptAll}
      onClickMoreOptions={() => setShowSettings(true)}
    />
  )
}
